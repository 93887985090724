<!-- Template -->
<template>
  <div class="o-half-menu">
    <i class="o-half-menu__icon"><IconDotGroup /></i>

    <i class="o-half-menu__icon"><IconFullCircle /></i>

    <section class="o-half-menu__actions">
      <button 
        type="button" 
        class="o-half-menu__actions__back"
        @click="handleBackClick"
      ><IconChevronLeft /></button>

      <div class="o-half-menu__actions__separator" />

      <button 
        type="button" 
        class="o-half-menu__actions__logo" 
        @click="handleLogoClick"
      ><IconConsignado /></button>
    </section>

    <section class="o-half-menu__content">
      <h1 class="o-half-menu__content__title">
        <strong class="o-half-menu__content__title__highlight">
          ALFA.
        </strong>
        <span class="o-half-menu__content__title__text">
          CUIDAMOS DO QUE
        </span>
        <strong class="o-half-menu__content__title__highlight">
          IMPORTA PARA VOCÊ!
        </strong>
      </h1>
    </section>
  </div>
</template>

<!-- Script -->
<script setup>
// Depedências
import { storeToRefs } from 'pinia'
import { useStepStore } from '~/stores/step'

// Composables
import useNavigation from '~/composables/useNavigation'

// Icones
import IconDotGroup from '~/assets/icons/IconDotGroup.vue'
import IconFullCircle from '~/assets/icons/IconFullCircle.vue'
import IconChevronLeft from '~/assets/icons/IconChevronLeft.vue'
import IconConsignado from '~/assets/icons/IconConsignado.vue'

// Pinia
const stepStore = useStepStore()
const { mainSteps, supportSteps } = storeToRefs(stepStore)
const { setMainStepId, setSupportStepId } = stepStore

// Variáveis
const navigation = useNavigation()

// Métodos
function handleBackClick() {
  const supportStepId = supportSteps.value.id - (supportSteps.value.id - 1)
  const mainStepId = mainSteps.value.id - (mainSteps.value.id - 1)

  if (supportStepId >= 1 && supportSteps.value.id > 1) {
    setSupportStepId(supportStepId)
    return
  }

  if (mainStepId >= 1 && mainSteps.value.id > 1) {
    setMainStepId(mainStepId)
    return
  }

  navigation.goBackwards()
}

function handleLogoClick() {
  navigation.go('/')
}
</script>

<!-- Styles -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.o-half-menu {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  
  @media screen and (min-width: $min-desktop) {
    grid-template-rows: auto 1fr auto;
    padding: 48px 40px;
  }

  &__icon {
    display: none;

    @media screen and (min-width: $min-desktop) {
      z-index: 998;
      display: block;
      position: absolute;

      &:nth-child(1) {
        top: -95px;
        right: 120px;
        width: 200px;
      }

      &:nth-child(2) {
        left: -375px;
        bottom: -160px;
        width: 600px;
      }
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: auto 1fr;
    place-content: center start;
    place-items: center;
    padding: 32px 24px;
    background-color: $alfa-brand-primary;
    @include box-shadow();

    @media screen and (min-width: $min-desktop) {
      grid-template-columns: repeat(3, auto);
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }

    &__back, 
    &__logo {
      cursor: pointer;
      background-color: transparent;
      border: 0;
    }

    &__separator {
      display: none;

      @media screen and (min-width: $min-desktop) {
        display: block;
        width: 2px;
        height: 80%;
        margin-left: 40px;
        margin-right: 24px;
        background-color: $alfa-neutral-primary-1;
        opacity: 0.2;
      }
    }
  }

  &__content {
    display: none;

    @media screen and (min-width: $min-desktop) {
      z-index: 999;
      display: grid;
      place-content: end;
    }

    &__title {
      display: grid;
      row-gap: 8px;

      &__highlight {
        @include font-custom(28, 30, left, "Montserrat SemiBold", $alfa-neutral-primary-1);
      }

      &__text {
        @include font-custom(28, 30, left, "Montserrat Thin", $alfa-neutral-primary-1);
      }
    }
  }
}
</style>
