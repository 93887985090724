<!-- Template -->
<template>
  <div class="l-auth">
    <aside class="l-auth__aside">
      <OrganismHalfMenu />
    </aside>

    <main class="l-auth__main" ref="scroll">
      <slot></slot>
    </main>

    <AtomLoading :is-active="isLoadingScreen" />

    <OrganismCookieManager />
  </div>
</template>

<!-- Script -->
<script setup>
// Dependências
import { storeToRefs } from 'pinia'
import { useScreenStore } from '@/stores/screen'

// Componentes
import OrganismHalfMenu from '~/components/transational/organisms/OrganismHalfMenu/OrganismHalfMenu.vue';
import AtomLoading from '~/components/atoms/AtomLoading/AtomLoading.vue'
import OrganismCookieManager from '~/components/institutional/organisms/OrganismCookieManager/OrganismCookieManager.vue'

// Roteamento
const route = useRoute()

// Dados computados
const title = computed(() => route.meta.title + ' | Alfa Empréstimo Consignado')

// Titulo  da página
useHead({ title })

// Pinia
const screenStore = useScreenStore()
const { isLoadingScreen } = storeToRefs(screenStore)
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.l-auth {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  place-content: start stretch;
  width: 100vw;
  height: 100svh;
  background-color: $alfa-neutral-primary-1;
  
  @media screen and (min-width: $min-desktop) {
    grid-template-columns: minmax(min-content, 50%) 1fr;
    grid-template-rows: 1fr;
    background-color: $alfa-brand-primary-1;
  }

  &__aside {
    display: grid;
    place-content: stretch;
    max-height: 100%;
    overflow: hidden;

    @media screen and (min-width: $min-desktop) {
      padding: 0;
    }
  }

  &__main {
    display: grid;
    place-content: stretch;
    overflow: hidden auto;
    padding: 0 24px;
    @include scrollbar();
    @include scrollbox-shadow();

    @media screen and (min-width: $tablet) {
      padding: 0 32px;
    }
    
    @media screen and (min-width: $min-desktop) {
      padding: 0 40px;
    }
  }
}
</style>
